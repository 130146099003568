import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './select.css'
import {
    MDBCheckbox, MDBSelect, MDBTextArea, MDBChipsInput

} from 'mdb-react-ui-kit';
import ErrorDialog from './errordialog';
import services from "../../helper/services";
import moment from 'moment';
import listitem from './checkuplistitem'
import { useSelector } from "react-redux";

const component = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const error = useRef();

    const { t } = useTranslation('translations');
    const dateAdapter = useSelector(state => state.lang);
    const [value, setValue] = React.useState([]);
    const [chipvalue, setChipvalue] = React.useState("");
    const [textAreaValue, setTextAreaValue] = useState("");
    const [expanded, setExpanded] = useState(true);
    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    };
    const [checked, setChecked] = useState(false);

    const [multiSelectValue, setMultiSelectValue] = useState([]);
    const textAreaRef = useRef();
    const handleKeyDown = function (e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    }
    const handleChange = (newValue, inputid) => {
        if (newValue.length > 0 && newValue[newValue.length - 1].isclear == "Y") {
            newValue = newValue.filter(el => el.isclear == "Y")
        }
        else {
            newValue = newValue.filter(el => el.isclear != "Y")
        }
        setValue(newValue);
        if (checked)
            setChecked(false);
    };

    const handleChecklist = (newValue, e) => {
        let newValueList = [];
        if (value.some(el => el.value == newValue.value)) {
            newValueList = value.filter(el => el.value !== newValue.value);
        }
        else {
            newValueList = newValueList.concat(value);
            newValueList.push(newValue);
        }

        setValue(newValueList);

    };
    const ischecked = (newValue) => {
        return value.some(el => el.value == newValue.value) || newValue.defaultSelected;
    }
    const handleCheck = () => {

        setChecked(!checked);

    };

    const handleTextAreaChange = (newValue) => {
        setTextAreaValue(newValue.target.value);
        if (checked)
            setChecked(false);

    };

    const deletechip = (name) => {
        var item = getChipValue();
        var newvalue = value.filter(e => e.text != name)
        setValue(newvalue);
    }

    const preparesecondaryText = (value) => {
        return <span className="select-option-secondary-text mb-2">{value}</span>
    }

    const getChipValue = () => ((props.defaultValue && multiSelectValue.length > 0) ? multiSelectValue.map(e => { return { ...e, "tag": e.text, closeIcon: false } }) : multiSelectValue.filter(el => value.some(v => v.value == el.value)).map(e => { return { ...e, "tag": e.text } }))

    useEffect(() => {

        if (props.defaultValue) {
            if (props.defaultValue[props.name]) {
                var result = Array.isArray(props.defaultValue[props.name]) ? props.defaultValue[props.name] : JSON.parse(props.defaultValue[props.name])

                setChecked(result.some(el => el == 'no'))
            }

            setTextAreaValue(props.defaultValue["item_other_" + props.name] ?? "");
        }

    }, []);


    useEffect(() => {

        const fetchData = async () => {

            //var newvalue = listitem.getitemvalue(t)[props.name];
            var newvalue1 = await services.getListItem(t, props.name, dateAdapter);
            var newvalue = newvalue1.map(object => ({ ...object }));
            if (props.basicInfo?.gender) {
                newvalue = newvalue.filter(el => !el.gender || el.gender == props.basicInfo?.gender)
            }
            if (props.minimal) {
                newvalue = newvalue.filter(el => !el.isfull || el.isfull != "Y")
            }
            setMultiSelectValue(newvalue)
            setTextAreaValue("");
            setChipvalue(new Date());

        }


        if (checked) {
            setValue([]);
            fetchData();
        }

    }, [checked]);
    const fetchData = async () => {

        var oldvalue = value;
        //var newvalue = listitem.getitemvalue(t)[props.name];
        var newvalue1 = await services.getListItem(t, props.name, dateAdapter);
        var newvalue = newvalue1.map(object => ({ ...object }));
        if (props.basicInfo?.gender) {
            newvalue = newvalue.filter(el => !el.gender || el.gender == props.basicInfo?.gender)
        }
        if (props.minimal) {
            newvalue = newvalue.filter(el => !el.isfull || el.isfull != "Y")
        }
        newvalue = newvalue.map(item => {
            if (item.secondaryText) {
                return { ...item, secondaryText: preparesecondaryText(item.secondaryText) }
            }
            else {
                return item;
            }

        })
        var selecteditem = oldvalue;
        if (selecteditem.length > 0) {
            newvalue = newvalue.map(item => {
                const item2 = selecteditem.find(i2 => i2.value === item.value);
                return item2 ? { ...item, defaultSelected: true } : item;
            });
        }
        if (props.defaultValue) {
            if (props.defaultValue[props.name]) {
                var result = Array.isArray(props.defaultValue[props.name]) ? props.defaultValue[props.name] : JSON.parse(props.defaultValue[props.name])

                var selecteditem = result;
                if (selecteditem.length > 0) {
                    newvalue = newvalue.filter(item => {
                        const item2exists = selecteditem.some(i2 => i2 === item.value);
                        if (item2exists) {
                            item.disabled = true;
                            item.defaultSelected = true
                        }
                        return item2exists;
                    });
                } else {
                    newvalue = [];
                }
            } else {
                newvalue = [];
            }
        }

        setMultiSelectValue(newvalue)
        setChipvalue(new Date());

    }
    useEffect(() => {
        
        fetchData();
    }, [dateAdapter, value]);

    useImperativeHandle(ref, () => ({
        getResult() {
            let data = {};
            if (checked) {
                data[props.name] = ['no'];
                return data;
            }
            data[props.name] = value.map(el => el.value);
            if (textAreaValue) {
                data[props.name].push("other");
                data["item_other_" + props.name] = textAreaValue;
            }
            value.forEach(el => data[el.value] = 1);
            if (data[props.name].length == 0) return {};
            return data;

        }
        , setSelectedvalues(items) {
            let selectedvalues = multiSelectValue.filter(e => items.some(i => i == e.value));
            setValue(selectedvalues);
        }

    }));
    return (
        <React.Fragment>
            {((!props.minimal && !props.readonly && !props.ischeckbox) || (props.readonly && checked)) && <div className="input-group border-0 mb-2 active">
                <MDBCheckbox value='1' label={t("no_" + props.name)} id={"no" + props.name}
                    checked={checked}
                    disabled={props.readonly}

                    onChange={handleCheck} />
            </div>}
            {!props.ischeckbox &&
                <>
                    {(!props.readonly || (props.readonly && multiSelectValue.length > 0 && !checked)) && <div className="form-outline mb-2">
                        <MDBSelect
                            search={props.search}
                            displayedLabels={2}
                            disabled={props.readonly && (multiSelectValue.length == 0 || checked)}
                            optionHeight={43}
                            label={t(props.label)}
                            multiple
                            value={value}
                            onValueChange={(e) => handleChange(e, props.name)}
                            selectAll={false}
                            data={multiSelectValue}
                        >

                        </MDBSelect>
                        {(value.length > 2 || (props.defaultValue && multiSelectValue.length > 2)) && <div className={"mt-2" + (props.readonly ? " readonly" : " ")}> <MDBChipsInput key={chipvalue + value}
                            label={t("Selected_" + props.label)}

                            readOnly
                            onDelete={deletechip}
                            initialValues={getChipValue()}
                        /></div>}
                    </div>}
                </>
            }
            {props.ischeckbox && multiSelectValue.filter(el => el.ischeckbox == "Y").length > 0 &&
                <div className="form-outline mb-2">
                    {multiSelectValue.filter(el => el.ischeckbox == "Y").map(el => (
                        <MDBCheckbox key={el.value} value={el.value} label={t(el.note2)} id={"checkbox-" + el.value}
                            onChange={e => handleChecklist(el, e)}
                            checked={ischecked(el) ?? false}
                            disabled={props.readonly} />
                    )

                    )
                    }

                </div>
            }
            {((!props.minimal && !props.readonly) || (props.readonly && textAreaValue)) && <div className="form-outline mb-2">

                <MDBTextArea label={t(props.otherlabel || "other")} onChange={(e) => handleTextAreaChange(e)}
                    value={textAreaValue}
                    readOnly={props.readonly}
                    rows="3"
                    maxLength="500"
                    onFocus={handleKeyDown}
                    onKeyDown={handleKeyDown} />
                {!props.ischeckbox && <div className='form-text'>{t("not calculate")}</div>}


            </div>}
        </React.Fragment>
    );
});





export default component;