import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBListGroup, MDBListGroupItem, MDBTextArea
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import Multipleoption from './multipleoption'

const component = forwardRef((props, ref) => {
    const [basicModal, setBasicModal] = useState(false);
    const dateAdapter = useSelector(state => state.lang);
    const [value, setValue] = useState({});
    const { t } = useTranslation('translations');
    function closeDialog() {
        setBasicModal(false);
        setValue({});
    }


    useImperativeHandle(ref, () => ({
        openModal: (item) => {
            setValue(item);
            setBasicModal(true);
        }
    }))

    return (
        <>
            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{t("Confirm Detail")}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={closeDialog}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBListGroup light>
                                {value.acknowledge == "Y" && <>
                                    <h6 className='bg-light p-2 border-top border-bottom'>{t("acknowledge")}</h6>
                                    <MDBListGroupItem className='text-start'><b>{t("Acknowledge by: ")}</b><br />{(value.acknowledge_name ?? "")}</MDBListGroupItem>
                                    <MDBListGroupItem className='text-start'><b>{t("Acknowledge Date Time: ")}</b><br />{value.acknowledge_datetime ? t("resultdatetime", {
                                        val: new Date(value.acknowledge_datetime), formatParams: {

                                            val: {
                                                lng: (dateAdapter == "th" ? "th" : "en-GB"),
                                                day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit',
                                                minute: '2-digit',
                                            },
                                        }
                                    }) : ""}</MDBListGroupItem>
                                    {value.confirm == "Y" && <h6 className='bg-light p-2 border-top border-bottom'>{t("contact_customer")}</h6>}

                                </>}
                                {value.confirm == "Y" && <>
                                    <MDBListGroupItem className='text-start'><b>{t("confirm by: ")}</b><br />{(value.confirm_name ?? "")}</MDBListGroupItem>
                                    <MDBListGroupItem className='text-start'><b>{t("confirm Date Time: ")}</b><br />{value.confirm_datetime ? t("resultdatetime", {
                                        val: new Date(value.confirm_datetime), formatParams: {

                                            val: {
                                                lng: (dateAdapter == "th" ? "th" : "en-GB"),
                                                day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit',
                                                minute: '2-digit',
                                            },
                                        }
                                    }) : ""}</MDBListGroupItem>
                                    <MDBListGroupItem className='text-start'>
                                    <b>{t("Remark: ")}</b>
                                    
                                        <Multipleoption ischeckbox={true} readonly={true} otherlabel="comment" defaultValue={value.confirm_comment} name={props.remark} />
                                        {/*  <b>{t("Comment: ")}</b><br />{<MDBTextArea className='text-muted' rows="3" disabled value={(value.confirm_comment ?? "")} ></MDBTextArea>}
 */}
                                    </MDBListGroupItem></>}
                            </MDBListGroup>



                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={closeDialog}>
                                {t("Close")}
                            </MDBBtn>

                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
});
export default component;