import React, { useState, useRef, useEffect } from "react";
import Menu from './sub/appbar';
import BasicinfoComponent from './sub/basicinfo';
import {
    MDBContainer, MDBInput
    , MDBDatepicker
    , MDBInputGroup,
    MDBCheckbox,
    MDBRadio, MDBBtn, MDBCollapse, MDBIcon, MDBSelect, MDBTextArea,

} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import services from '../helper/services';
import ErrorDialog from './sub/errordialog';
import { useSelector } from "react-redux";

import EntryPersonalHealth from './sub/entrypersonalhealth';
import HistoryPersonalHealth from './sub/historypersonalhealth';

export default function Main() {
    const [expanded, setExpanded] = useState(false);
    const { state } = useLocation();
    let [selected, setSelected] = useState(null);
    const navigate = useNavigate();
    const error = useRef();

    const personalHealth = useRef();
    const historypersonalHealth = useRef();


    const { t } = useTranslation('translations');
    const [userprofile, setUserprofile] = useState({});


    const [wait, setWait] = useState(false);
    const [normalRange, setNormalRange] = useState([]);
    const [currenthn, setCurrenthn] = useState({});
    const [HNList, setHNList] = useState();
    const [s_showDiv, setS_showDiv] = useState("");

    const handleRadioChange = (e) => {


        setS_showDiv(e.target.getAttribute("data-value"));

    };


    async function fetchuserprofile() {

        try {
            var res = await services.fetchuserprofile()
            setUserprofile(res)
            const hnlist = res.userproperties.filter(el => el.name == "hn").map(e => ({ value: e.hn, secondaryText: e.hn, text: e.alias }));
            setHNList(hnlist);
            if (hnlist.length > 0)
                setCurrenthn(res.userproperties.filter(el => el.name == "hn")[0])
            else {
                navigate("/")
            }
        } catch (e) {
            error.current.showerror(e);
        }

    }

    useEffect(() => {

        const fetchData = async () => {
            var newvalue = await services.getListItem(t, "normalrange", '');
            setNormalRange(newvalue);

        }
        fetchData();


        fetchuserprofile();
    }, []);


    async function handleSubmit(e, page) {
        var errormessage = "";
        e.preventDefault();
        if (wait) return;
        setWait(true);
        try {
            let data = {}

            if (!personalHealth.current.checkvalidField()) {
                throw "Required field"
            }

            data.personalHealth = personalHealth.current.getResult();

            if (data.personalHealth.length !== 0) {

                for (var item of normalRange) {
                    const resultitem = data.personalHealth.find(e => e.name === item.name)
                    if (resultitem && resultitem.value != "") {
                        var binding = new Function('value', 'return ' + item.value);
                        resultitem.flag = binding(resultitem.value);
                    }
                }

                try {
                    setWait(true);

                    var result = await services.savePersonalHealth(data);
                    error.current.showmessage("Saved", () => { setS_showDiv("history") })
                    setWait(false);

                } catch (ex) {
                    console.log(ex);
                    error.current.showerror(ex);
                    setWait(false);
                }
            } else {
                throw "Required field"
            }

        } catch (e) {
            console.log(e);
            error.current.showerror(e)
            setWait(false);
        }
    }
    return (<>
        <Menu requirelogin="true" title={t("Personal Health")} />
        <div className="container">
            <section className='pt-4 p-md-4'>


                <div className="mx-0 mx-sm-auto">
                    <div className="card">
                        <div className="card-body px-1 px-mb-2">
                            <div className="text-center">
                                <i className="fas fa-file-waveform fa-4x mb-3 text-primary"></i>

                                {HNList &&
                                    <div className="row justify-content-center">
                                        <div className='col col-xl-4 col-lg-6 col-md-8 mb-3 '>
                                            <MDBInputGroup noBorder textBefore={t("personal health for")}>
                                                <MDBSelect data={HNList}
                                                    value={currenthn?.hn}
                                                    onValueChange={(e) => setCurrenthn({ hn: e.value, alias: e.text })}
                                                />
                                            </MDBInputGroup>
                                        </div>
                                    </div>
                                }
                            </div>

                            <hr />

                            {currenthn?.hn &&
                                <><div className="container-fluid text-center ">
                                    <div className="row mb-2">
                                        <div className="col ">
                                            <label className="">
                                                <div className={`btn btn-lg btn${s_showDiv === "history" ? "-primary" : "-secondary"}`} style={{ borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>
                                                    <input type="radio" className="form-check-input d-none" id="s_personalhealth_history" name="s_personalhealth" value="s_chkup_topic" data-value="history"
                                                        checked={s_showDiv === "history"}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {t("History: ")}

                                                </div>
                                            </label>
                                        </div>
                                        <div className="col">

                                            <label className="">
                                                <div className={`btn btn-lg btn${s_showDiv === "input" ? "-primary" : "-secondary"}`} style={{ borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>
                                                    <input type="radio" className="form-check-input d-none" id="s_personalhealth_input" name="s_personalhealth" value="s_symptom_topic" data-value="input"
                                                        checked={s_showDiv === "input"}
                                                        onChange={handleRadioChange} />
                                                    {t("Input: ")}

                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row p-md-4 pt-md-2">
                                        {s_showDiv === "input" && <form onSubmit={(e) => handleSubmit(e)}>
                                            <ErrorDialog ref={error} />
                                            <EntryPersonalHealth hn={currenthn?.hn} ref={personalHealth} />
                                            <div className="card-footer text-end">
                                                <MDBBtn type="submit" disabled={wait}>{t("Submit")}</MDBBtn>
                                            </div>
                                        </form >
                                        }
                                        {s_showDiv === "history" && <>
                                            <ErrorDialog ref={error} />
                                            <HistoryPersonalHealth key={currenthn?.hn} hn={currenthn?.hn} ref={historypersonalHealth} normalRange={normalRange} />

                                        </>}
                                    </div>
                                </div>
                                </>}
                        </div>
                    </div>
                </div>

            </section >
        </div >


    </>
    );
}