export default {
    userprofile: null,
    username: null,
    logout: function () {
        this.userprofile = null;
        this.username = null;
        window.sessionStorage.clear();
    },
    listitem: {},
    googlesignin: async function (jwtToken) {
        if (this.userprofile) return this.userprofile;
        var res = await fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/googlesignin", {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({ token: jwtToken }),
        })
            .then((res) => res.json())
        if (res.success) {
            return res;

        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    },
    applesignin: async function (jwtToken) {
        if (this.userprofile) return this.userprofile;
        var res = await fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/applesignin", {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({ token: jwtToken }),
        })
            .then((res) => res.json())
        if (res.success) {
            return res;

        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    },
    getpersonalentry: async function (token) {

        var res = await fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/externalpersonalentry", {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",

            },
            body: JSON.stringify(token),
        })
            .then((res) => res.json())
        if (res.success) {
            return JSON.parse(Buffer.from(res.data, 'base64').toString());

        } else {
            return;
        }
    },
    fetchuserprofile: async function () {
        if (this.userprofile) return this.userprofile;
        var res = await fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/getuserprofile", {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify({}),
        })
            .then((res) => res.json())
        if (res.success) {
            if (res.data.userprofile.dob) {
                res.data.userprofile.dobstring = new Date(res.data.userprofile.dob).toLocaleDateString('en-CA')
                res.data.userprofile.dob = new Date(res.data.userprofile.dob)
                res.data.userprofile.age = window.calculateAge(res.data.userprofile.dobstring);
                this.userprofile = res.data.userprofile;
                return this.userprofile;
            }
            return res.data.userprofile;

        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    },
    fetchusername: async function () {
        if (this.username) return this.username;
        var res = await fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/getusername", {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify({}),
        })
            .then((res) => res.json())

        if (res.success) {
            this.username = res.data.username;
            return this.username;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    },
    getTopicList: async function (param) {

        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/getsmdfieldsv2/getsmd_topic', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify({ data: param }),
        }).then((res) => res.json())
            .then(response => response.data.page)
            .then((data) => {
                if (data)
                    return data;
            });
        return res;
    },
    convertstyle: function (style) {
        function camelCase(key) {
            return key.replace(/-([a-z])/ig, function (all, letter) {
                return letter.toUpperCase();
            });
        }
        if (style) {

            var newStyle = {};
            for (var o in style) {
                newStyle[camelCase(o)] = style[o];
            }
            return newStyle;
        } else return {}

    },
    getPageById: async function (topic_id, page_id, profile) {
        var param = {
            topic_id: topic_id,
            page_id: page_id,
            age: profile.age,
            gender: profile.gender
        };
        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/getsmdfieldsv2/getsmd_pagebyidandtopicid', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify({ data: param }),
        })
            .then((res) => res.json())
            .then(response => response.data.page)
            .then((data) => {
                if (data)
                    return data;
            });
        return res;
    },
    saveSymptomCheckerResult: async function (destination_logic, currenttopic_id, answer, profile, self_exam, checkup) {
        var param = {
            self_exam: self_exam,
            age: profile.age,
            dob: profile.dobstring,
            gender: profile.gender,
            answer: answer,
            currenttopic_id: currenttopic_id,
            checkup: checkup,
            onepage: "Y"
        };
        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/symptomchecker/' + destination_logic, {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify({ data: param }),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }

    },
    saveCheckupResult: async function (destination_logic, currenttopic_id, answer, profile, self_exam) {
        var param = {
            self_exam: self_exam,
            age: profile.age,
            dob: profile.dobstring,
            gender: profile.gender,
            answer: answer,
            currenttopic_id: currenttopic_id,
            checkup: "Y",
            onepage: "N"
        };
        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/symptomchecker/' + destination_logic, {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify({ data: param }),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }

    },
    searchPersonalHealth: async function (param) {

        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/personalhealth/search', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify(param),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }

    },
    deletepersonalhealth: async function (param) {

        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/personalhealth/delete', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify(param),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }

    },
    savePersonalHealth: async function (data) {

        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/personalhealth/save', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify({ data: data }),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }

    },
    updateProfile: async function (items, isfull) {

        items.dob = items.dob.toJSON()
        items.age = window.calculateAge(items.dob);

        var errormessage = "";
        if (isNaN(items.age) || items.age < 0) {

            errormessage = "Invalid";

        } else if (items.age > 150 || items.age < 10) {

            errormessage = "Invaliddate";
        }
        if (errormessage) {
            throw errormessage;
        }
        if (isfull) {
            if (items.given_name == "" || items.family_name == "" || items.tel == "") {

                errormessage = "Invalid info";
            }
        }

        var res = await fetch(process.env.REACT_APP_DOMAIN + (isfull ? "apismd/smd_c/user/updatefullprofile" : "apismd/smd_c/user/updateprofile"), {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token") ?? ""
            },
            body: JSON.stringify(items),
        })
            .then((res) => res.json())
            .catch(errormsg => {
                throw ("There are something wrong")
            });

        if (res.success) {
            this.userprofile = null;
            return "Profiled updated";
        } else {
            console.log(res.error);
            throw (res.error.map(e => e.text || e.message || e).join(' '))
        }



    },

    updatepersonalhealthProfile: async function (items) {



        var res = await fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/updatepersonalhealthProfile", {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token") ?? ""
            },
            body: JSON.stringify(items),
        })
            .then((res) => res.json())
            .catch(errormsg => {
                throw ("There are something wrong")
            });

        if (res.success) {
            this.userprofile = null;
            return "Profiled updated";
        } else {
            console.log(res.error);
            throw (res.error.map(e => e.text || e.message || e).join(' '))

        }



    },
    getDestination: async function (destionations, profile, currentanswer) {
        var selecteddestination;
        if (destionations) {


            for (const destination of destionations) {
                var criteriaanswer = Object.assign({ skip: 0 })
                var criteriafunction = [];
                criteriafunction.push("c");
                criteriafunction.push("age");
                criteriafunction.push("gender");
                var criteria = new Function(criteriafunction, `try{ return ` + (destination.criteria || 'true') + `; }catch(e){console.log(e);return false}`);
                var param = []

                for (var ans of currentanswer) {
                    criteriaanswer = Object.assign(criteriaanswer, ans);
                }


                param.push(criteriaanswer);
                param.push(profile.age);
                param.push(profile.gender);


                var result = criteria(...param);
                if (result) {
                    selecteddestination = destination;
                    break;
                }
            }

        }
        return selecteddestination;
    },
    groupBy: function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    },
    getformdata: function (form, value) {
        let data = {};
        for (const pair of (new FormData(form)).entries()) {
            if (pair[0].startsWith("date-") && value[pair[0]]) {
                pair[1] = value[pair[0]].toLocaleDateString('en-CA');
                data[pair[0].replace("date-", "")] = pair[1];
                continue;
            }

            if (!data[pair[0]] || (data[pair[0]] && data[pair[0]] == "other")) {
                if (pair[1]) {
                    data[pair[0]] = pair[1];
                    if (data[pair[0]] && data[pair[0]] == "other") {
                        data[pair[0] + "checked"] = 1;
                        data[pair[0]] = "";
                    }

                }
            }

        }
        return data;

    },
    getResult: async function (id) {
        var param = {
            transaction_id: id
        };
        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/result/get', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify(param),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    },
    updateResult: async function (id, type, confirm_type, comment) {
        var param = {
            transaction_id: id,
            comment: comment?.comment,
            commentlist: comment?.commentlist,
            confirm_type: confirm_type,
            type: type
        };
        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/result/complete', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify(param),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    },
    updatePersonalResult: async function (id, comment) {
        var param = {
            transaction_id: id,
            comment: comment.comment,
            commentlist: comment.commentlist,
            type: "P"
        };
        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/result/complete', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify(param),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    },
    searchResult: async function (param, path) {

        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/result/search' + (path ?? ""), {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify(param),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }

    },

    getconfirmdetail: async function (transaction_id, type) {
        const param = {
            transaction_id: transaction_id,
            type: type
        }
        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/result/confirmdetail', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify(param),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    },

    postFeedback: async function (param) {

        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/feedback/post', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify(param),
        })
            .then((res) => res.json())
        if (res.success) {
            return res.data;
        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    },
    getListItem: async function (t, name, lang) {
        if (this.listitem[name + lang]) return this.listitem[name + lang];

        var param = {
            list_name: name
        };
        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/listitem/get', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify(param),
        })
            .then((res) => res.json())
        if (res.success) {
            var items = res.data;
            this.listitem[name + lang] = items.map(e => {
                var i = { ...e };
                i.text = t(e.note);
                i.note2 = e.note;
                if (e.gender == "B")
                    delete i.gender;
                delete i.note;
                delete i.item_property;
                if (e.item_property)
                    i = Object.assign({}, i, JSON.parse(e.item_property));

                return i;
            })

            return this.listitem[name + lang];



        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    },
    processNLP: async function (value) {
        var res = await fetch(process.env.REACT_APP_DOMAIN + 'apismd/smd_c/nlp/get', {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token")
            },
            body: JSON.stringify({ value: value }),
        })
            .then((res) => res.json())
        if (res.success) {
            var items = res.data;
            return items;



        } else {
            console.log(res.error);
            throw res.error.map(e => e.text || e.message || e).join(' ')
        }
    }





}