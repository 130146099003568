import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBTextArea
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import Multipleoption from './multipleoption'
import ErrorDialog from './errordialog';

const component = forwardRef((props, ref) => {
    const [basicModal, setBasicModal] = useState(false);
    const [value, setValue] = useState('');
    const { t } = useTranslation('translations');
    const [callBack, setCallback] = useState({});
    const remark = useRef();
    const error = useRef();

    const [wait, setWait] = useState(false);

    function closeDialog() {
        setBasicModal(false);
        setValue('');
        setCallback({});
        setWait(false);
    }

    function save(e) {

        e.preventDefault();
        try {
            if (wait) return;
            setWait(true);
            let data = { ...remark.current?.getResult() };
            if (!data[props.remark]) {
                throw "Required field"
            }

            if (callBack.callback)
                callBack.callback(data);
            closeDialog();
        } catch (e) {
            console.log(e);
            error.current.showerror(e)
            setWait(false);
        }
    }

    useImperativeHandle(ref, () => ({
        openModal: (_callBack) => {
            setCallback({ callback: _callBack });
            setBasicModal(true);
        }
    }))

    return (
        <>
            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>

                        <form onSubmit={save}>
                            <MDBModalHeader>
                                <MDBModalTitle>{t("contact_customer_header")}</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' type='button' onClick={closeDialog}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <ErrorDialog ref={error} />
                                <div className="row text-start">
                                    <div className="col col-md-10">
                                        <p className="fw-bold mb-2">{t("Remark")}</p>
                                        <div className="ms-mb-3 mb-3">
                                            <Multipleoption key={new Date()} name={props.remark} label="Remark" ref={remark} minimal={false} ischeckbox={true} otherlabel="comment" />
                                        </div>
                                    </div>
                                </div>



                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn color='secondary' type='button' onClick={closeDialog}>
                                    {t("Close")}
                                </MDBBtn>
                                <MDBBtn type='submit'>{t("Save")}</MDBBtn>
                            </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
});
export default component;