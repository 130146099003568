import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
} from 'mdb-react-ui-kit';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import services from '../../helper/services';
import FeedbackModal from './feedback'
import { useSelector } from "react-redux";

const Appbar = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const dateAdapter = useSelector(state => state.lang);
    const [userprofile, setUserprofile] = useState({});
    const feeedback = useRef();
    const [showBasic, setShowBasic] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const { t, i18n } = useTranslation('translations');
    const token = useRef('');
    const navigate = useNavigate();
    const location = useLocation();
    const [wait, setWait] = useState(false);
    const [username, setUsername] = useState('');
    token.current = window.sessionStorage.getItem("token") ?? "";
    useEffect(() => {
        dispatch({ type: "changeLanguage", payload: (i18n.language.toLowerCase() == "th" || i18n.language.toLowerCase() == "th-th" ? "th" : "en") })
        if (props.requirelogin == "true" && token.current == "") {

            navigate("/login" + (location.pathname != "/" ? "?returnurl=" + location.pathname : ""))
        }
    }, []);

    async function changeLanguageHandler(lang) {
        await i18n.changeLanguage(lang)
        setWait(false)
        setShowBasic(false)
        dispatch({ type: "changeLanguage", payload: lang })
        props.LanguageChangeCallback?.apply(this, [lang]);
        window.changeLanguage?.postMessage(lang.startsWith("th") ? "th" : "en");

    }

    async function fetchuserprofile() {

        try {
            var res = await services.fetchuserprofile()
            setUserprofile(res)
        } catch (e) {
            //error.current.showerror(e);
        }

    }

    function logout() {
        services.logout();
        window.Logout?.postMessage("Logout");
        navigate("/login")
    }
    async function fetchusername() {
        if (username != '') return;
        try {
            var result = await services.fetchusername();
            if (result)
                setUsername(result);
            else {
                navigate("/login" + (location.pathname != "/" ? "?returnurl=" + location.pathname : ""))
            }
        }
        catch (ex) {
            navigate("/login" + (location.pathname != "/" ? "?returnurl=" + location.pathname : ""))
        }


    }
    function getPreviousPage() {
        props.PreviousCallback();
    }

    useImperativeHandle(ref, () => ({ getUsername: () => { return username } }), [username])
    useEffect(() => {
        if (token.current) { fetchusername(); fetchuserprofile(); }
    }, []);

    return (
        <>
            <MDBNavbar expand='xl' fixed='top' light bgColor='light' onBlur={() => !wait && setShowBasic(false)}>
                <MDBContainer fluid className='align-items-start'>

                    {/* Mobile */}
                    <div className="navbar-nav me-auto d-flex d-xl-none flex-row">
                        {props.allowPrevious &&
                            <button onClick={getPreviousPage} className="navbar-toggler">

                                <MDBIcon fas icon="angle-left text-secondary" fixed />

                            </button>
                        }

                        {!props.title && <MDBNavbarBrand className='mx-auto' href={username ? "/" : "/login"} onClick={(e) => e.preventDefault() | navigate(username ? "/" : "/login")}  ><img
                            src={process.env.PUBLIC_URL + '/logo192.png'}
                            height='40'
                            alt=''
                            loading='lazy'
                        /></MDBNavbarBrand>}
                        {props.title && <MDBNavbarBrand style={{ zoom: "0.9" }} className='fs-6 mx-auto'>{props.title}</MDBNavbarBrand>}
                    </div>


                    <div className="navbar-nav ms-auto d-flex d-xl-none">
                        <MDBNavbarToggler style={!props.title ? { height: "49px" } : {}}
                            className='text-end'
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => setShowBasic(!showBasic)}
                        >
                            <MDBIcon icon='bars' fas fixed />
                        </MDBNavbarToggler>

                        <MDBCollapse navbar show={showBasic} className='text-end'>
                            <ul className="navbar-nav d-flex d-xl-none">

                                <MDBNavbarItem>
                                    <MDBNavbarLink active aria-current='page' href={username ? "/" : "/login"} onClick={(e) => e.preventDefault() | navigate(username ? "/" : "/login")}>
                                        {t("Home")}
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                {username &&
                                    <>
                                        <MDBNavbarItem>
                                            <MDBNavbarLink aria-current='page' href='/history' onClick={(e) => e.preventDefault() | navigate("/history")}>
                                                {t("View history")}
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>
                                        {userprofile?.userproperties?.filter(el => el.name == "hn").length > 0 && <MDBNavbarItem>
                                            <MDBNavbarLink aria-current='page' href='/personalhealth' onClick={(e) => e.preventDefault() | navigate("/personalhealth")}>
                                                {t("Personal Health")}
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>}
                                        {["admin", "staff"].includes(userprofile?.role) && <MDBNavbarItem>
                                            <MDBNavbarLink aria-current='page' href='/admin/history' onClick={(e) => e.preventDefault() | navigate("/admin/history")}>
                                                {t("Admin History")}
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>}
                                        {!["admin", "staff"].includes(userprofile?.role) && <MDBNavbarItem>
                                            <MDBNavbarLink aria-current='page' href='#' onClick={(e) => {
                                                e.preventDefault();
                                                setShowFeedback(true);
                                                feeedback.current.showFeedbackModal()
                                            }}>
                                                {t("Provide Feedback")}
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>}
                                    </>
                                }
                            </ul>

                            <ul className="navbar-nav d-flex d-xl-none">

                                {token.current ? <> <MDBNavbarItem>
                                    <MDBNavbarLink href="/manageuser" onClick={(e) => e.preventDefault() | navigate("/manageuser")}>{username}</MDBNavbarLink>
                                </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink href="#" aria-current='page' onClick={logout}>
                                            {t("Logout")}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem></>
                                    : <MDBNavbarItem>
                                        <MDBNavbarLink href="/login" active aria-current='page' onClick={(e) => e.preventDefault() | navigate("/login")}>
                                            {t("login")}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                }
                                <MDBNavbarItem>
                                    <MDBDropdown >
                                        <MDBDropdownToggle tag='a' className='nav-link text-uppercase' role='button' onTouchStart={() => setWait(true)} onClick={() => setWait(true)}>
                                            {i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            <MDBDropdownItem link onClick={(e) => e.preventDefault() | changeLanguageHandler("en")}>EN</MDBDropdownItem>
                                            <MDBDropdownItem link onClick={(e) => e.preventDefault() | changeLanguageHandler("th")}>TH</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                            </ul>
                        </MDBCollapse>
                    </div>

                    {/* Desktop */}
                    <div className=' w-100 d-none d-xl-flex align-items-top'>
                        <div className="navbar-nav me-auto w-100 d-none d-xl-flex">



                            {props.allowPrevious &&
                                <MDBNavbarBrand href="#" onClick={getPreviousPage} >

                                    <MDBIcon fas icon="angle-left text-secondary" fixed />

                                </MDBNavbarBrand>
                            }
                            {<><MDBNavbarBrand className='py-0' href={username ? "/" : "/login"} onClick={(e) => e.preventDefault() | navigate(username ? "/" : "/login")}><img
                                src={process.env.PUBLIC_URL + '/logo192.png'}
                                height='40'
                                alt=''
                                loading='lazy'
                            /></MDBNavbarBrand></>}
                            <ul className="navbar-nav">

                                <MDBNavbarItem>
                                    <MDBNavbarLink active aria-current='page' href={username ? "/" : "/login"} onClick={(e) => e.preventDefault() | navigate(username ? "/" : "/login")}>
                                        {t("Home")}
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                {username &&
                                    <><MDBNavbarItem>
                                        <MDBNavbarLink aria-current='page' href='/history' onClick={(e) => e.preventDefault() | navigate("/history")}>
                                            {t("View history")}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                        {userprofile?.userproperties?.filter(el => el.name == "hn").length > 0 && <MDBNavbarItem>
                                            <MDBNavbarLink aria-current='page' href='/personalhealth' onClick={(e) => e.preventDefault() | navigate("/personalhealth")}>
                                                {t("Personal Health")}
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>}
                                        {["admin", "staff"].includes(userprofile?.role) && <MDBNavbarItem>
                                            <MDBNavbarLink aria-current='page' href='/admin/history' onClick={(e) => e.preventDefault() | navigate("/admin/history")}>
                                                {t("Admin History")}
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>}
                                        {!["admin", "staff"].includes(userprofile?.role) && <MDBNavbarItem>
                                            <MDBNavbarLink aria-current='page' href='#' onClick={(e) => e.preventDefault() | setShowFeedback(true) | feeedback.current.showFeedbackModal()}>
                                                {t("Provide Feedback")}
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>}
                                    </>
                                }
                            </ul>
                        </div>

                        <div className="navbar-nav mx-auto d-none d-xl-flex">


                            {props.title && <MDBNavbarBrand className='mx-auto'>{props.title}</MDBNavbarBrand>}

                        </div>
                        <div className="navbar-nav ms-auto w-100 d-none d-xl-flex">
                            <div className="navbar-nav ms-auto ">
                                {token.current ? <> <MDBNavbarItem>
                                    <MDBNavbarLink href="/manageuser" onClick={(e) => e.preventDefault() | navigate("/manageuser")}>{t("User: ") + username}</MDBNavbarLink>
                                </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink href="#" aria-current='page' onClick={logout}>
                                            {t("Logout")}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem></>
                                    : <MDBNavbarItem>
                                        <MDBNavbarLink href="/login" active aria-current='page' onClick={(e) => e.preventDefault() | navigate("/login")}>
                                            {t("login")}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                }
                                <MDBNavbarItem>
                                    <MDBDropdown >
                                        <MDBDropdownToggle tag='a' className='nav-link text-uppercase' role='button' id="lang">
                                            {i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            <MDBDropdownItem link onClick={(e) => e.preventDefault() | changeLanguageHandler("en") | document.getElementById("lang").click()}>EN</MDBDropdownItem>
                                            <MDBDropdownItem link onClick={(e) => e.preventDefault() | changeLanguageHandler("th") | document.getElementById("lang").click()}>TH</MDBDropdownItem>

                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                            </div>
                        </div></div>



                </MDBContainer>
            </MDBNavbar >
            <FeedbackModal key={showFeedback} show={showFeedback} ref={feeedback} onClose={() => setShowFeedback(false)} />
        </>
    );
});

export default Appbar;