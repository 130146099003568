import {
    MDBBtn,
    MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
}
    from 'mdb-react-ui-kit';
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

function Component() {
    const { t, i18n } = useTranslation('translations');
    const [disclaimerModal, setDisclaimerModal] = useState(false);
    const dispatch = useDispatch();


    const toggleShow = (modal) => {
        window.sessionStorage.setItem("acceptDisclaimer", true)
        setDisclaimerModal(!disclaimerModal);
    }

    function changeLanguageHandler(lang) {
        i18n.changeLanguage(lang)
        dispatch({ type: "changeLanguage", payload: lang })
        window.changeLanguage?.postMessage(lang.startsWith("th") ? "th" : "en");
    }


    useEffect(() => {
        if (!window.sessionStorage.getItem("acceptDisclaimer"))
            setDisclaimerModal(true)
    }, []);
    return (<MDBModal show={disclaimerModal} tabIndex='-1'>
        <MDBModalDialog className='modal-fullscreen'>
            <MDBModalContent>
                <MDBModalHeader className='justify-content-center'>
                    <MDBModalTitle dangerouslySetInnerHTML={
                        { __html: t("Disclaimer", { interpolation: { escapeValue: false } }) }
                    }></MDBModalTitle>

                </MDBModalHeader>
                <MDBModalBody className='text-start' dangerouslySetInnerHTML={
                    { __html: t("DisclaimerText" + (process.env.REACT_APP_HOSPITAL ?? ""), { interpolation: { escapeValue: false } }) }
                }></MDBModalBody>

                <MDBModalFooter className='justify-content-between'>
                    <MDBDropdown group className='shadow-0'>
                        <MDBDropdownToggle color='link' >{i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}</MDBDropdownToggle>
                        <MDBDropdownMenu responsive='end'>
                            <MDBDropdownItem link onClick={(e) => { e.preventDefault(); changeLanguageHandler("en")}}>EN</MDBDropdownItem>
                            <MDBDropdownItem link onClick={(e) => {e.preventDefault(); changeLanguageHandler("th")}}>TH</MDBDropdownItem>

                        </MDBDropdownMenu>
                    </MDBDropdown>

                    <MDBBtn color='secondary' onClick={toggleShow}>
                        {t(`Accept`)}
                    </MDBBtn>

                </MDBModalFooter>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal >)
}

export default Component;