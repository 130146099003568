
import ErrorDialog from './sub/errordialog';
import Complete from './sub/complete';
import Menu from './sub/appbar';
import EnTryHN from './sub/entryhn';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBRow,
    MDBInput,
    MDBCheckbox,
    MDBIcon,
    MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBRadio,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtnGroup
}
    from 'mdb-react-ui-kit';
import React, { useCallback, useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import services from '../helper/services';
import GoogleAuth from './sub/googleauth';
import AppleAuth from './sub/appleauth';
import { useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFnsBuddhist from '../helper/date-fns-be';
import { th, enUS } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function Register() {
    const error = useRef();
    const hnlist = useRef();
    const [value, setValue] = useState(null);
    const [complete, setcomplete] = useState(false);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('translations');
    const dateAdapter = useSelector(state => state.lang);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [termModal, setTermModal] = useState(false);
    const [privacyModal, setPrivacyModal] = useState(false);
    const { state } = useLocation();
    const [organization_purpose, setorganization_purpose] = useState("");
    const [token, setToken] = useState(null);
    const [socialpersonalentry, setsocialpersonalentry] = useState(state?.socialpersonalentry);
    useEffect(() => {
        if (state) {
            setToken(state.token);

        }

    }, []);
    const toggleShow = (modal) => {
        if (modal === "Term")
            setTermModal(!termModal);
        else
            setPrivacyModal(!privacyModal);
    }
    function changeLanguageHandler(lang) {
        i18n.changeLanguage(lang)

    }
    const handlecheckbox = (e) => {
        if (e.currentTarget.checked) {
            setorganization_purpose(e.currentTarget.value)
        }

    }

    const handleSumitForm = useCallback(
        (e) => {
            e.preventDefault();

            if (!executeRecaptcha) {
                //console.log("Execute recaptcha not yet available");
                return;
            }

            const items = {};
            for (const pair of (new FormData(e.target)).entries()) {

                items[pair[0]] = pair[1];
            }

            if (process.env.REACT_APP_IMPLEMENT == "HOSPITAL") {

                if (!items.dob || !items.gender) {
                    error.current.showerror("Invalid info")
                    return;
                }
                else {
                    items.dob = window.parseDate(items.dob, dateAdapter)
                    items.dobstring = items.dob.toLocaleDateString('en-CA')
                }

                items.hnlist = hnlist.current?.getResult();

                items.family_name = items.family_name.substring(0, 3) + "***"
                items.implement = process.env.REACT_APP_IMPLEMENT;
            }

            items.lang = dateAdapter;


            executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
                //console.log(gReCaptchaToken, "response Google reCaptcha server");
                items.gRecaptchaToken = gReCaptchaToken;
                submitEnquiryForm(items);
            });
        },
        [executeRecaptcha]
    );
    const googlesignin = async (jwtToken) => {


        try {
            const res = await services.googlesignin(jwtToken);

            if (res.data.message == "notverified") {
                error.current.showmessage("account not verified", () => { navigate("/verify") })
            } else if (res.data.message == "active") {
                window.sessionStorage.setItem("token", res.data.access_token);
                navigate("/index")
            }
            else if (res.data.message == "new") {
                const token = { token: jwtToken, platform: "google" };
                const entry = await services.getpersonalentry(token);
                //setToken({ token: jwtToken, platform: "google" });
                navigate("/externalregister", { state: { token: token, socialpersonalentry: entry } })
            }
        } catch (e) {
            error.current.showerror(e)
        }




    }

    const applesignin = async (jwtToken, user) => {


        try {
            const res = await services.applesignin(jwtToken);

            if (res.data.message == "notverified") {
                error.current.showmessage("account not verified", () => { navigate("/verify") })
            } else if (res.data.message == "active") {
                window.sessionStorage.setItem("token", res.data.access_token);
                navigate("/index")
            }
            else if (res.data.message == "new") {
                const token = { token: jwtToken, platform: "apple" };
                const entry = { email: user.email };
                navigate("/externalregister", { state: { token: token, socialpersonalentry: entry } })
            }
        } catch (e) {
            error.current.showerror(e)
        }




    }

    const externalsignup = (items) => {
        try {
            fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/externalregister", {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(items),
            })
                .then((res) => res.json())
                .then((res) => {

                    if (res.success) {
                        window.sessionStorage.setItem("token", res.data.access_token);
                        navigate("/index")
                    } else {
                        error.current.showerror(res.error.map(e => e.text ?? e).join(' '))
                    }

                });
        } catch (e) {
            error.current.showerror(e)
        }

    };
    const submitEnquiryForm = (items) => {
        if (state || token)
            externalsignup({ ...items, ...state, ...state.token, ...token });
        else {
            fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/register", {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(items),
            })
                .then((res) => res.json())
                .then((res) => {

                    if (res.success) {
                        setcomplete(true);
                    } else {
                        error.current.showerror(res.error.map(e => e.text ?? e).join(' '))
                    }

                });
        }
    };

    return (<>
        <Menu />
        <MDBContainer fluid={!complete} className='mt-2'>
            {!complete ? <MDBRow className='g-0 pt-5'>
                <MDBCol xl='3' ></MDBCol>
                <MDBCol md='12' xl='6' >
                    <MDBCard className='mx-md-5 p-md-5 shadow-5 mt-12 '>
                        <MDBCardBody className='p-5 pb-2 text-center'>

                            <div className='d-flex flex-row align-items-start'>
                                <img
                                    src={process.env.PUBLIC_URL + '/logo192.png'}
                                    height='48'
                                    alt=''
                                    loading='lazy'
                                />

                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex flex-row '>
                                    <h3 className="fw-bold mb-5">{t(token ? 'Enter Username' : 'Sign up now')}</h3>
                                </div>
                                <MDBDropdown group className='shadow-0  mb-5'>
                                    <MDBDropdownToggle color='link' >{i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}</MDBDropdownToggle>
                                    <MDBDropdownMenu responsive='end'>
                                        <MDBDropdownItem link onClick={() => changeLanguageHandler("en")}>EN</MDBDropdownItem>
                                        <MDBDropdownItem link onClick={() => changeLanguageHandler("th")}>TH</MDBDropdownItem>

                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </div>
                            <ErrorDialog ref={error} />
                            <form onSubmit={handleSumitForm}>

                                {!token && <MDBInput wrapperClass='mb-4' label={t('Enter Email')} labelClass='required' id='email' type='email' name='email' required />}
                                <MDBInput wrapperClass='mb-4' label={t('Enter Username')} labelClass='required' id='username' type='username' name="username" required maxLength={100} />
                                {!token && <MDBInput wrapperClass='mb-4' label={t('Enter Password')} labelClass='required' id='password' type='password' name='password' required maxLength={100} />}
                                {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && <>
                                    <MDBInput wrapperClass='mb-4' label={t('Enter Tel')} labelClass='required' id='tel' type='tel' name="tel"
                                        onInput={function (e) { e.target.value = e.target.value.replace(/[^0-9-+]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                                        required maxLength={100} />
                                    <MDBInput wrapperClass='mb-4' label={t('Enter Name')} labelClass='required' id='given_name' name="given_name" required defaultValue={socialpersonalentry?.given_name} />
                                    <MDBInput wrapperClass='mb-4' label={t('Enter Surname')} labelClass='required' id='family_name;' name="family_name" required defaultValue={socialpersonalentry?.family_name} />
                                </>}
                                {process.env.REACT_APP_IMPLEMENT != "HOSPITAL" &&
                                    <><div className='d-flex justify-content-center pt-2'>
                                        <p className="pb-lg-1" style={{ color: '#3B4054' }}>{t(`usage_purpose`)}</p>
                                    </div>
                                        <div className='mb-4 d-flex justify-content-start row'>
                                            <div className='col col-12 text-start'>
                                                <MDBRadio name='usage_purpose' label={t('personal_use')} id="personal" value="personal" onChange={handlecheckbox} required />
                                            </div>
                                            <div className='col col-12 mb-2 text-start'>
                                                <MDBRadio name='usage_purpose' label={t('organization_use')} id="organization" value="organization" onChange={handlecheckbox} />
                                            </div>
                                            {organization_purpose == "organization" && <div className='col col-12'>
                                                <MDBInput label={t('Enter Organization')} id='organization' name="organization" />
                                            </div>}
                                        </div></>}

                                {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && <>
                                    {< div className="mb-3">
                                        <label htmlFor="dob" className="form-label required">{t("Date of Birth")}</label>
                                        {dateAdapter !== "th" && <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS} >

                                            <div><DatePicker
                                                label={t("dob")}
                                                minDate={new Date(((new Date().getFullYear()) - 80).toString() + '-01-01')}
                                                maxDate={new Date((new Date()).setFullYear( new Date().getFullYear() - 18 ))}
                                                onChange={(newValue) => setValue(newValue)}
                                                value={value}
                                                slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "dob", name: "dob"  ,readOnly: true, } }}
                                                format="dd/MMM/yyyy"

                                            /></div>

                                        </LocalizationProvider>}
                                        {dateAdapter == "th" && <LocalizationProvider dateAdapter={AdapterDateFnsBuddhist} adapterLocale={th} >

                                            <div><DatePicker
                                                label={t("dob")}
                                                minDate={new Date(((new Date().getFullYear()) - 80).toString() + '-01-01')}
                                                maxDate={new Date((new Date()).setFullYear( new Date().getFullYear() - 18 ))}
                                                onChange={(newValue) => setValue(newValue)}
                                                value={value}
                                                slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "dob", name: "dob" ,  readOnly: true,} }}
                                                id="dob" name="dob"
                                                format="dd/MMM/yyyy"
                                            /></div>

                                        </LocalizationProvider>}
                                    </div>
                                    }
                                    {<div className="mb-3 pb-3">
                                        <label className="form-label required">{t("gender")}</label>
                                        <br />
                                        <MDBBtnGroup shadow='0' >
                                            <MDBRadio btn outline="true" btnColor='light' id='btn-male' name='gender' value="M" label={<div><MDBIcon fas icon='male' className='me-2' />{t("Male")}</div>} />

                                            <MDBRadio btn outline="true" btnColor='light' id='btn-female' name='gender' value="F" label={<div><MDBIcon fas icon='female' className='me-2' />{t("Female")}</div>} />
                                        </MDBBtnGroup>
                                    </div>
                                    }

                                    <EnTryHN ref={hnlist} /></>}

                                <div className='d-flex justify-content-center mb-4'>

                                    <p className="pb-lg-2" style={{ color: '#3B4054' }}>{t(`When you ${token ? 'continue' : 'sign up '} you agree to The `)}
                                        <a href="#" style={{ color: '#3461FD' }} onClick={() => toggleShow("Term")}>{t(`Terms of Service`)}</a>{t(` and `)}
                                        <a href="#" style={{ color: '#3461FD' }} onClick={() => toggleShow("Privacy")}>{t(`Privacy policy`)}</a></p>
                                </div>

                                <MDBBtn className='w-100 mb-4' size='lg'>{t(token ? 'Continue' : 'Sign up')}</MDBBtn>
                            </form>
                            {!token && <div className="text-center">

                                <div className='justify-content-center text-center mb-3'>
                                    <div className="row">
                                        <div className="col">
                                            <hr style={{ border: '1px solid #61677D' }} />
                                        </div>
                                        <div className="col align-self-center">
                                            <p className="text-center fw-bold mx-3 mb-0 text-muted">{t("OR")}</p>
                                        </div>
                                        <div className="col">
                                            <hr style={{ border: '1px solid #61677D' }} />
                                        </div>
                                    </div>
                                </div>
                                {!window.isinAppBrowser() ?
                                    <>
                                        <div className='text-center mb-3'>
                                            <div className="d-flex justify-content-center text-center row my-2">
                                                <div className='col-auto'> <GoogleAuth onSuccess={googlesignin} /></div>
                                            </div>
                                            <div className="d-flex justify-content-center text-center row  my-2">
                                                <div className='col-auto'>
                                                    <AppleAuth onSuccess={applesignin} label={t("Sign up with Apple")} />
                                                </div>
                                            </div>
                                        </div>
                                    </> : <>
                                        <div className='justify-content-center text-center  text-muted fw-bold mb-3'>
                                            <p>{t("in app browser not support")}</p>
                                        </div>
                                    </>}

                            </div>}

                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol xl='3' ></MDBCol>
            </MDBRow> : <MDBRow className='g-0 mt-2'><Complete callback={() => navigate("/verify")} title="complete register" desc="complete register desc" btn="verify" /></MDBRow>}

            <MDBModal show={termModal} setShow={setTermModal} tabIndex='-1'>
                <MDBModalDialog className='modal-fullscreen'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{t(`Terms of Service`)}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={() => toggleShow("Term")}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody dangerouslySetInnerHTML={
                            { __html: t("TOS" + (process.env.REACT_APP_HOSPITAL ?? ""), { interpolation: { escapeValue: false } }) }
                        }></MDBModalBody>

                        <MDBModalFooter className='justify-content-between'>
                            <MDBDropdown group className='shadow-0'>
                                <MDBDropdownToggle color='link' >{i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}</MDBDropdownToggle>
                                <MDBDropdownMenu responsive='end'>
                                    <MDBDropdownItem link onClick={() => changeLanguageHandler("en")}>EN</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => changeLanguageHandler("th")}>TH</MDBDropdownItem>

                                </MDBDropdownMenu>
                            </MDBDropdown>

                            <MDBBtn color='secondary' onClick={() => toggleShow("Term")}>
                                {t(`OK`)}
                            </MDBBtn>

                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal show={privacyModal} setShow={setPrivacyModal} tabIndex='-1'>
                <MDBModalDialog className='modal-fullscreen'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{t(`Privacy policy`)}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={() => toggleShow("Privacy")}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody dangerouslySetInnerHTML={
                            { __html: t("Privacy" + (process.env.REACT_APP_HOSPITAL ?? ""), { interpolation: { escapeValue: false } }) }
                        }></MDBModalBody>

                        <MDBModalFooter className='justify-content-between'>
                            <MDBDropdown group className='shadow-0'>
                                <MDBDropdownToggle color='link' >{i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}</MDBDropdownToggle>
                                <MDBDropdownMenu responsive='end'>
                                    <MDBDropdownItem link onClick={() => changeLanguageHandler("en")}>EN</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => changeLanguageHandler("th")}>TH</MDBDropdownItem>

                                </MDBDropdownMenu>
                            </MDBDropdown>

                            <MDBBtn color='secondary' onClick={() => toggleShow("Privacy")}>
                                {t(`OK`)}
                            </MDBBtn>

                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </MDBContainer>
    </>
    );
}

export default Register;