import React, { useState, useRef, useEffect } from "react";
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInputGroup,
    MDBInput,
    MDBRadio, MDBBtnGroup, MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter, MDBDatepicker
} from 'mdb-react-ui-kit';
import Menu from './sub/appbar';
import ErrorDialog from './sub/errordialog';
import services from '../helper/services';
import EnTryHN from './sub/entryhn';
import Complete from './sub/complete';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


import { useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFnsBuddhist from '../helper/date-fns-be';
import { th, enUS } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export default function Manageuser() {

    const dateAdapter = useSelector(state => state.lang);
    const { t, i18n } = useTranslation('translations');
    const error = useRef();
    const appBar = useRef();
    const hnlist = useRef();
    const navigate = useNavigate();
    const [complete, setcomplete] = useState(false);
    const [loaduserprofilecomplete, setLoaduserprofilecomplete] = useState(false);
    const [username, setusername] = useState("");
    const [userprofile, setUserprofile] = useState({});
    const [value, setValue] = useState(userprofile.dob);
    const [deleteconfirmModal, setDeleteconfirmModal] = useState(false);


    const [verticalActive, setVerticalActive] = useState('profile');

    const handleVerticalClick = (value) => {
        if (value === verticalActive) {
            return;
        }

        setVerticalActive(value);
    };

    const toggleShow = () => setDeleteconfirmModal(!deleteconfirmModal);

    async function fetchuserprofile() {

        try {
            var res = await services.fetchuserprofile()
            setUserprofile(res)
        } catch (e) {
            error.current.showerror(e);
        }

    }

    const submitdeleteaccount = () => {
        toggleShow();
        fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/deleteaccount", {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token") ?? ""
            },
            body: JSON.stringify({}),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success) {
                    window.sessionStorage.removeItem("token");
                    window.Logout?.postMessage("Logout");
                    error.current.showmessage("Account Deleted", () => { navigate("/login") });
                } else {
                    error.current.showerror(res.error.map(e => e.text || e.message || e).join(' '))
                }

            })
            .catch(errormsg => {
                error.current.showerror("There are something wrong")
            });
    }

    const submitupdateprofile = async (e) => {
        e.preventDefault();

        const items = {};
        for (const pair of (new FormData(e.target)).entries()) {

            items[pair[0]] = pair[1];
        }

        if (!items.dob || !items.gender) {
            error.current.showerror("Invalid info")
            return;
        }
        else {
            items.dob = window.parseDate(items.dob, dateAdapter)
            items.dobstring = items.dob.toLocaleDateString('en-CA')
        }

        if (process.env.REACT_APP_IMPLEMENT == "HOSPITAL") {


            items.hnlist = hnlist.current?.getResult();


        }

        if (items.family_name)
            items.family_name = items.family_name.substring(0, 3) + "***"

        try {
            var res = await services.updateProfile(items, (process.env.REACT_APP_IMPLEMENT == "HOSPITAL"));

            error.current.showmessage(res)
        } catch (e) {
            error.current.showerror(e)
        }
    }

    const submitupdatepersonalhealth = async (e) => {
        e.preventDefault();

        const items = {};
        if (process.env.REACT_APP_IMPLEMENT == "HOSPITAL") {


            items.hnlist = hnlist.current?.getResult();


        }

        try {
            var res = await services.updatepersonalhealthProfile(items);

            error.current.showmessage(res)
        } catch (e) {
            error.current.showerror(e)
        }
    }

    const ongenderChange = (e) => {

        setUserprofile({ ...userprofile, gender: e.currentTarget.value });
    }

    const submitconfirmepassword = (e) => {

        e.preventDefault();

        const items = {};
        for (const pair of (new FormData(e.target)).entries()) {

            items[pair[0]] = pair[1];
        }

        if (!items.newpassword || !items.oldpassword || items.newpassword.length < 6 || items.newpassword == items.oldpassword) {
            error.current.showerror("Invalid password")
            return;
        }
        fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/changepassword", {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "authorization": "Bearer " + window.sessionStorage.getItem("token") ?? ""
            },
            body: JSON.stringify(items),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success) {
                    error.current.showmessage("Password changed");
                } else {
                    error.current.showerror(res.error.map(e => e.text || e.message || e).join(' '))
                }

            })
            .catch(errormsg => {
                error.current.showerror("There are something wrong")
            });
    };
    useEffect(() => {
        fetchuserprofile();
    }, []);
    return (
        <>
            <Menu ref={el => { if (!el) return; appBar.current = el; setusername(appBar.current.getUsername()) }} requirelogin="true" title={t("Manage User")} />
            <div className="container">
                <section className='py-4'>
                    <ErrorDialog ref={error} />
                    <MDBRow>
                        <div className="col-md-3 d-none d-md-block">
                            <div className="card">
                                <div className="card-body">
                                    <MDBTabs pills className='flex-column'>
                                        <MDBTabsItem>
                                            <MDBTabsLink className="px-3"  onClick={() => handleVerticalClick('profile')} active={verticalActive === 'profile'}>
                                                <MDBIcon fas icon='user' className='me-2' />{t("Profile Information")}
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                        {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && <MDBTabsItem>
                                            <MDBTabsLink className="px-3" onClick={() => handleVerticalClick('personalhealth')} active={verticalActive === 'personalhealth'}>
                                                <MDBIcon fas icon='user-nurse' className='me-2' />{t("Personalhealth Information")}
                                            </MDBTabsLink>
                                        </MDBTabsItem>}
                                        <MDBTabsItem>
                                            <MDBTabsLink className="px-3"  onClick={() => handleVerticalClick('account')} active={verticalActive === 'account'}>
                                                <MDBIcon fas icon='cog' className='me-2' />{t("Account settings")}
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                        {!userprofile.externalsignup && <MDBTabsItem>
                                            <MDBTabsLink className="px-3"  onClick={() => handleVerticalClick('security')} active={verticalActive === 'security'}>
                                                <MDBIcon fas icon="lock" className='me-2' />{t("Security")}
                                            </MDBTabsLink>
                                        </MDBTabsItem>}

                                    </MDBTabs>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header border-bottom mb-3 d-flex d-md-none">
                                    <MDBTabs >
                                        <MDBTabsItem>
                                            <MDBTabsLink onClick={() => handleVerticalClick('profile')} active={verticalActive === 'profile'}>
                                                <MDBIcon fas icon='user' />
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                        {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && <MDBTabsItem>
                                            <MDBTabsLink onClick={() => handleVerticalClick('personalhealth')} active={verticalActive === 'personalhealth'}>
                                                <MDBIcon fas icon='user-nurse' />
                                            </MDBTabsLink>
                                        </MDBTabsItem>}
                                        <MDBTabsItem>
                                            <MDBTabsLink onClick={() => handleVerticalClick('account')} active={verticalActive === 'account'}>
                                                <MDBIcon fas icon='cog' />
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                        {!userprofile.externalsignup && <MDBTabsItem>
                                            <MDBTabsLink onClick={() => handleVerticalClick('security')} active={verticalActive === 'security'}>
                                                <MDBIcon fas icon="lock" />
                                            </MDBTabsLink>
                                        </MDBTabsItem>}

                                    </MDBTabs>
                                </div>
                                <div className="card-body">
                                    <MDBTabsContent>
                                        <MDBTabsPane show={verticalActive === 'profile'}>
                                            <h6>{t("YOUR PROFILE INFORMATION")}</h6>
                                            <hr />
                                            <div className="form-group">
                                                <form onSubmit={submitupdateprofile}>
                                                    <label className="form-label">{t("Username")}</label>
                                                    <div className="small text-muted mb-3">{username}</div>
                                                    {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && userprofile.tel !== undefined && <MDBInput wrapperClass='mb-4' label={t('Enter Tel')} labelClass='required' id='tel' type='tel' name="tel"
                                                        onInput={function (e) { e.target.value = e.target.value.replace(/[^0-9-+]/g, '').replace(/(\..*?)\..*/g, '$1'); }}
                                                        required maxLength={100} defaultValue={userprofile?.tel} />}
                                                    {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && userprofile.given_name !== undefined && <MDBInput wrapperClass='mb-4' label={t('Enter Name')} labelClass='required' id='given_name' name="given_name" required defaultValue={userprofile?.given_name} />}
                                                    {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && userprofile.family_name !== undefined && <MDBInput wrapperClass='mb-4' label={t('Enter Surname')} labelClass='required' id='family_name;' name="family_name" required defaultValue={userprofile?.family_name} />}

                                                    {userprofile.gender && < div className="mb-3 col col-md-4">
                                                        <label htmlFor="dob" className="form-label">{t("Date of Birth")}</label>
                                                        {dateAdapter !== "th" && <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS} >

                                                            <div><DatePicker
                                                                defaultValue={userprofile.dob}
                                                                minDate={new Date(((new Date().getFullYear()) - 80).toString() + '-01-01')}
                                                                maxDate={new Date((new Date()).setFullYear(new Date().getFullYear() - 18))}
                                                                onChange={(newValue) => setValue(newValue)}
                                                                value={value}
                                                                slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "dob", name: "dob" } }}
                                                                format="dd/MMM/yyyy"
                                                            /></div>

                                                        </LocalizationProvider>}
                                                        {dateAdapter == "th" && <LocalizationProvider dateAdapter={AdapterDateFnsBuddhist} adapterLocale={th} >

                                                            <div><DatePicker
                                                                defaultValue={userprofile.dob}
                                                                minDate={new Date(((new Date().getFullYear()) - 80).toString() + '-01-01')}
                                                                maxDate={new Date((new Date()).setFullYear(new Date().getFullYear() - 18))}
                                                                onChange={(newValue) => setValue(newValue)}
                                                                value={value}
                                                                slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "dob", name: "dob" } }}
                                                                id="dob" name="dob"
                                                                format="dd/MMM/yyyy"
                                                            /></div>

                                                        </LocalizationProvider>}
                                                    </div>
                                                    }
                                                    {userprofile.gender && <div className="mb-3">
                                                        <label className="form-label">{t("gender")}</label>
                                                        <br />
                                                        <MDBBtnGroup shadow='0' >
                                                            <MDBRadio btn outline="true" btnColor='light' id='btn-male' name='gender' value="M" defaultChecked={userprofile.gender === "M"} label={<div><MDBIcon fas icon='male' className='me-2' />{t("Male")}</div>} />

                                                            <MDBRadio btn outline="true" btnColor='light' id='btn-female' name='gender' value="F" defaultChecked={userprofile.gender === "F"} label={<div><MDBIcon fas icon='female' className='me-2' />{t("Female")}</div>} />
                                                        </MDBBtnGroup>
                                                    </div>
                                                    }


                                                    <MDBBtn>{t("Update Profile")}</MDBBtn>
                                                </form>
                                            </div>
                                        </MDBTabsPane>
                                        <MDBTabsPane show={verticalActive === 'personalhealth'}>
                                            <h6>{t("YOUR PERSONALHEALTH INFORMATION")}</h6>
                                            <hr />
                                            <form onSubmit={submitupdatepersonalhealth}>
                                                {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && userprofile.userproperties && <EnTryHN ref={hnlist} hnList={userprofile.userproperties} errorref={error} />}
                                                <MDBBtn>{t("Update Profile")}</MDBBtn>
                                            </form>
                                        </MDBTabsPane>
                                        <MDBTabsPane show={verticalActive === 'account'}><h6>{t("ACCOUNT SETTINGS TITLE")}</h6><hr />

                                            <div className="form-group">
                                                <label className="d-block text-danger">{t("Delete Account")}</label>
                                                <p className="text-muted font-size-sm">{t("Once you delete your account, there is no going back. Please be certain.")}</p>
                                            </div>
                                            <MDBBtn color='danger' onClick={toggleShow}>{t('Delete Account')}</MDBBtn>

                                        </MDBTabsPane>
                                        {!userprofile.externalsignup && <MDBTabsPane show={verticalActive === 'security'}><h6>{t("SECURITY SETTINGS")}</h6><hr />
                                            <form onSubmit={submitconfirmepassword}>
                                                <div className="form-group mb-3 col col-md-4" >
                                                    <label className="d-block">{t("Change Password")}</label>
                                                    <input type="password" className="form-control mt-2" name="oldpassword" placeholder={t("Enter your old password")} />
                                                    <input type="password" className="form-control mt-2" name="newpassword" placeholder={t("New password")} />

                                                </div>
                                                <MDBBtn>{t("Change Password")}</MDBBtn>
                                            </form>

                                        </MDBTabsPane>}

                                    </MDBTabsContent>
                                </div>
                            </div>
                        </div>
                    </MDBRow >
                </section >
            </div >
            <MDBModal show={deleteconfirmModal} setShow={setDeleteconfirmModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{t("Delete Account")}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>{t("Confirm Delete Account")}</MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleShow}>
                                {t("Close")}
                            </MDBBtn>
                            <MDBBtn color='danger' onClick={submitdeleteaccount}>{t("Delete Account")}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}