import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BasicInfo from "./basicinfo";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInputGroup,
    MDBInput,
    MDBRadio, MDBBtnGroup, MDBBtn,
    MDBBadge, MDBListGroup, MDBListGroupItem, MDBAutocomplete

} from 'mdb-react-ui-kit';
import ErrorDialog from './errordialog';
import services from "../../helper/services";

const component = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const error = useRef();

    const { t } = useTranslation('translations');
    const [userprofile, setUserprofile] = useState(props.userprofile);

    const [autoCompleteoption, setAutoCompleteoption] = useState([]);
    const [optionalautoCompleteoption, setOptionalautoCompleteoption] = useState([]);
    const [listitems, setListitems] = useState([]);
    const [page, setPage] = useState({ note: "" });

    const handleSubmit = (e) => {
        e.preventDefault();

        const items = {};
        for (const pair of (new FormData(e.target)).entries()) {

            items[pair[0]] = pair[1];
        }

        if (!items.gender)
            error.current.showerror("No gender")

        if (!items.dob)
            error.current.showerror("No dob")

        items.age = window.calculateAge(items.dob);

        props.callback?.apply(this, [items]);

    };
    async function fetchuserprofile() {

        try {
            var res = await services.fetchuserprofile()
            setUserprofile(res)
            getTopicList(res);
        } catch (e) {
            error.current.showerror(e);
        }

    }
    async function nextPage(answer) {
        var selecteddestination = await services.getDestination(page.destinations, userprofile, [{
            topiclist: answer.value
        }])
        navigate("/symptomchecker" + "/" + answer.value + (props.self ? "/self" : ""), { state: { profile: userprofile, destination: selecteddestination } })

    }
    async function getTopicList(profile) {
        if (!profile) return;
        try {
            var page = await services.getTopicList(profile)
            if (page) {
                setAutoCompleteoption(page.items.filter(e => e.checkup !== "Y").map(e => ({ "label": e.note, optional: e.optional, ...(e.item_property ? JSON.parse(e.item_property) : { value: "1" }) })))
                setOptionalautoCompleteoption(
                    page.items.filter(e => e.optional === "A" && e.checkup !== "Y").map(e => ({ "label": e.note, ...(e.item_property ? JSON.parse(e.item_property) : { value: "1" }) })));
                setPage(page);

            }
        } catch (e) {
            error.current.showerror(e);
        }

    }
    useEffect(() => {
        if (props.self) {
            fetchuserprofile();
        }
        else
            getTopicList(userprofile);


    }, []);

    useImperativeHandle(ref, () => ({
        setuserprofile(profile) {
            setUserprofile(profile)
            getTopicList();
        },

    }));
    const filterByCallback = (option, props) => {
        return t(option.label).toLowerCase().indexOf(props.text.toLowerCase()) !== -1 || (option.label).toLowerCase().indexOf(props.text.toLowerCase()) !== -1
    }
    function mapitem(e) {

        return (<a key={"item-" + e.value} href={"/symptomchecker" + "/" + e.value + (props.self ? "/self" : "")} className="list-group-item list-group-item-action"
            onClick={(ev) => ev.preventDefault() | nextPage(e)}>
            <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t(e.label)}</h5>
                <MDBIcon fas icon="angle-right fa-2x text-primary" />
            </div>

        </a>)
    }
    return (
        <div className="container-fluid col col-md-6 text-start">
            <ErrorDialog ref={error} />
            {userprofile && <>
                {!(autoCompleteoption && autoCompleteoption.filter(e => e.optional !== "A" && e.optional !== "Y").length == 0) && <Typeahead className="mb-2"
                    id="autocomplete"
                    labelKey="label"
                    options={autoCompleteoption}
                    filterBy={filterByCallback}
                    renderMenu={(results, menuProps) => (
                        Object.keys(menuProps).forEach(key => menuProps[key] === undefined ? delete menuProps[key] : {}) ||
                        <Menu {...menuProps}>
                            {results.map((result, index) => (
                                <MenuItem key={"item-" + index} option={result} position={index}>
                                    {t(result.label)}
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                    onChange={(ev) => nextPage(ev[0])}
                    placeholder={t("search")}
                />}

                <div className="list-group">
                    {page.items && autoCompleteoption && autoCompleteoption.filter(e => e.optional !== "A" && e.optional !== "Y").length == 0 &&
                        <h6 className='bg-light p-2 border-top border-bottom text-danger text-center'>{t("no related symptom checker for current age")}</h6>}
                    {autoCompleteoption && autoCompleteoption.filter(e => e.optional !== "A" && e.optional !== "Y").map(mapitem)
                    }
                </div></>}
        </div>
    );
});



export default component;