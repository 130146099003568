import React, { useState, useRef, useEffect } from "react";
import Menu from './sub/appbar';
import './result.css'
import BasicinfoComponent from './sub/basicinfo';
import {
    MDBContainer, MDBInput
    , MDBDatepicker
    , MDBInputGroup,
    MDBCheckbox,
    MDBRadio, MDBBtn, MDBCollapse, MDBIcon
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import services from '../helper/services';
import ErrorDialog from './sub/errordialog';
import CheckupAnswer from './sub/checkupanswer';
import ResultItem from './sub/resultitem'
import FeedbackModal from './sub/feedback'
import Interpretremark from './sub/interpretremark'

export default function Result() {
    window.shownfeedback = false;
    const [expanded, setExpanded] = useState(false);
    let { id, view } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const error = useRef();
    const { t } = useTranslation('translations');
    const age = useRef();
    const dob = useRef();
    const gender = useRef();
    const [htmlresult, setHtmlresult] = useState();
    const [result, setResult] = useState({});
    const [basicInfo, setBasicInfo] = useState({});
    const [urgentcolor] = useState(["", "success", "warning", "danger"]);
    const [openRef, setOpenRef] = useState(false);
    const [wait, setWait] = useState(false);
    let othercheck = {};
    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);

    };


    useEffect(() => {
        if (expanded)
            fixheight();
    }, [expanded])
    function fixheight() {
        setTimeout(() => {
            if (document.getElementById("collapaseexpandcontaineranswer"))
                document.getElementById("collapaseexpandcontaineranswer").style.height = null;
        }, 300);

    }
    async function getResult(id) {
        try {
            if (wait) return;
            setWait(true)
            var result = await services.getResult(id);
            if (result) {
                setBasicInfo({ dob: result.dob, gender: result.gender, age: result.age })
                result.items = services.groupBy(result.items, "topic_id");
                setResult(result);
                if (result.logic_result.risk_dz?.length != 0 && result.logic_result.ddx?.length == 0) {
                  
                    if (result.logic_result.risk_dz2.length == 0) {
                        result.logic_result.risk_dz2.push({ code: "norisk_dz" })
                    }

                    result.logic_result.incident.forEach(el => el.disablescore = true);
                }
                setHtmlresult(result.logic_result);

                setWait(false)
            } else {
                throw "invalid id";
            }
        } catch (e) {
            error.current.showerror(e);
            setWait(false)
        }

    };

    useEffect(() => {
        if (id !== "undefined")
            getResult(id);
        else {
            error.current.showerror("invalid id");
        }
    }, [])


    function generateTreeV2(obj, noicon) {
        if (obj) {
            obj = obj.sort(function (a, b) { return b.score - a.score })
            var main = services.groupBy(obj, "level");
            var items = Object.keys(main).sort(function (a, b) { return b.toString().localeCompare(a.toString()) })

            return <div className="row justify-content-center">{items.map((el, j) => <div key={j} className="col col-12 text-start ">
                <>{main[el].map((object, i) => <div className="" key={"level" + el + "child" + i}>
                    <ResultItem object={object} noicon={noicon} />

                </div>)}
                </></div>
            )}</div>
        }

    }

    return (<>
        <Menu allowPrevious={state?.hasPrevious == "Y"} PreviousCallback={() => navigate(state?.previous || "/history")} requirelogin="true" title={!basicInfo.gender ? t("Result") : (t("Result title") + (state?.given_name ? state?.given_name + " " + state?.family_name + " " : "") + t(basicInfo.gender == "M" ? "Male" : "Female") + ' ' + t("Agelabel", { age: (basicInfo.age == 0 ? window.calculateAgeMonth(basicInfo.dob) : basicInfo.age) }) + ' ' + t(basicInfo.age == 0 ? "M" : "Y"))} />
        <div className="container">
            <section className='pt-4 p-md-4'>
                <div className="container-fluid text-center py-2">
                    <ErrorDialog ref={error} />
                    {/*           {state?.contact != "N" && <FeedbackModal show={state?.showfeedback} delay={20000} resultId={id} />} */}
                    <div className="justify-content-center">
                        <div className="bg-light shadow">
                            <div className="row">
                                <div className="col ">

                                    <h5 className="border-bottom border-1" style={{ color: '#2C2F78' }}>
                                        {result.self_exam == "Y" && <><i className="fas fa-user-alt m-2" style={{ fontSize: '20px', color: '#2C2F78' }}></i>{t("Self assessment") + " " + t("Topic") + ": " + t(result.note)} </>}
                                        {result.self_exam == "N" && <><i className="fas fa-users m-2" style={{ fontSize: '20px', color: '#2C2F78' }}></i>{t("Not-self assessment") + " " + t("Topic") + ": " + t(result.note)}</>}
                                    </h5>
                                </div>
                            </div>

                            {htmlresult?.urgency && <div className="row mx-2">
                                <div className="col-md-12 mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className={"fas fa-square me-2 mb-2  text-" + urgentcolor[htmlresult?.urgency]}></i>{t("urgencylevel" + htmlresult?.urgency)}</h5>
                                            <p className="mb-2 text-muted">
                                                {t("urgencydescriptionlevel" + htmlresult?.urgency)}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>}
                            <div className="row mx-2 justify-content-center">
                                {htmlresult?.ddx?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest diag")}</h5>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.ddx, true)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}

                                {htmlresult?.inv?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest inv")}</h5>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.inv)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}

                                {htmlresult?.insurance_exclusion?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest insurance")}</h5>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.insurance_exclusion, true)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {htmlresult?.other?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest other")}</h5>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.other)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {htmlresult?.risk_dz2?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest risk_dz")}</h5>
                                            <p className="suggest_desc" dangerouslySetInnerHTML={
                                                { __html: t("suggest_risk_dz_des", { interpolation: { escapeValue: false } }) }
                                            }></p>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.risk_dz2, true)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}

                                {htmlresult?.screening_inv?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest screening_inv")}</h5>
                                            <p className="suggest_desc" dangerouslySetInnerHTML={
                                                { __html: t("suggest_screening_inv_des", { interpolation: { escapeValue: false } }) }
                                            }></p>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.screening_inv)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {htmlresult?.incident?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest Incident")}</h5>
                                            <p className="suggest_desc" dangerouslySetInnerHTML={
                                                { __html: t("suggest_incident_des", { interpolation: { escapeValue: false } }) }
                                            }></p>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.incident)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {htmlresult?.package_2?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest package_2")}</h5>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.package_2)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {htmlresult?.package_no_hospital?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest package")}</h5>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.package_no_hospital)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {htmlresult?.vac?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest vac")}</h5>
                                            <p className="suggest_desc" dangerouslySetInnerHTML={
                                                { __html: t("suggest_vac_des", { interpolation: { escapeValue: false } }) }
                                            }></p>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.vac)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}

                                {htmlresult?.insurance_policy?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest insurance_policy")}</h5>
                                            <p className="suggest_desc" dangerouslySetInnerHTML={
                                                { __html: t("suggest_insurance_policy_des", { interpolation: { escapeValue: false } }) }
                                            }></p>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.insurance_policy)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}

                                {htmlresult?.supplements?.length > 0 && <div className="col-md-6 col-grow mb-4">
                                    <div className="card">
                                        <div className="card-body mt-3 mb-2">
                                            <h5><i className="fas fa-square text-primary me-2 mb-2"></i>{t("suggest supplements")}</h5>
                                            <p className="suggest_desc" dangerouslySetInnerHTML={
                                                { __html: t("suggest_supplements_des", { interpolation: { escapeValue: false } }) }
                                            }></p>
                                            <div className="mb-2">
                                                {
                                                    generateTreeV2(htmlresult?.supplements)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}

                                {htmlresult?.comment && <div className="col-md-12 mb-2">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="mx-2">
                                                {
                                                    t(htmlresult?.comment)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {htmlresult?.note && <div className="col-md-12 mb-2">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="mxb-2">
                                                {
                                                    t(htmlresult?.note)
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}

                                {state?.contact !== "N" && process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && <div className="col-md-12 mb-2">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row justify-content-start">
                                                <div className="col-sm-auto py-1 text-start">
                                                    <MDBBtn tag='a' size="lg" color="info" href={t("appointment_url" + (process.env.REACT_APP_HOSPITAL ?? ""))} target="blank">
                                                        <MDBIcon fas icon="calendar-check" className="pe-2" />{t("Make appointment")}
                                                    </MDBBtn>
                                                </div>
                                                <div className="col-sm-auto py-1 text-start">
                                                    <MDBBtn tag='a' size="lg" color="info" href={t("tel_contact" + (process.env.REACT_APP_HOSPITAL ?? ""))}>
                                                        <MDBIcon fas icon="phone" className="pe-2" />{t("contact")}
                                                    </MDBBtn>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {htmlresult?.reference?.length > 0 && <div className="col-md-12 mb-2">
                                    <div className="card">
                                        <div className="card-body text-start align-top">
                                            <div className="col" onClick={() => setOpenRef(!openRef)}
                                            >
                                                <h5>{t("Reference")}
                                                    <i className={"fas ms-2 fa-angle-" + (openRef ? "up" : "down")} style={{ fontSize: '20px', color: '#2C2F78' }}></i>
                                                </h5>

                                            </div>
                                            <MDBCollapse id="collapaseexpandcontainer" show={openRef}>
                                                <div className="mx-2 d-flex flex-column">
                                                    {htmlresult?.reference.sort((a, b) => a.label.localeCompare(b.label)).map(function (object, i) {
                                                        return <a key={i} className="text-muted text-decoration-underline mb-2" href={object.url} target="_blank">{t(object.label)}<i className="fas fa-up-right-from-square text-muted ms-1"></i></a>;
                                                    })}
                                                </div>
                                            </MDBCollapse>

                                        </div>
                                    </div>
                                </div>}


                                {result?.items && <div className="col-md-12 mb-2">
                                    <div className="card">
                                        <div className="card-body text-start align-top">
                                            <div className="col" onClick={handleExpandClick}
                                            >
                                                <h5>{t("Your Answer")}
                                                    <i className={"fas ms-2 fa-angle-" + (expanded ? "up" : "down")} style={{ fontSize: '20px', color: '#2C2F78' }}></i>
                                                </h5>

                                            </div>

                                            <MDBCollapse id="collapaseexpandcontaineranswer" show={expanded} onClick={() => fixheight()}>
                                                <div className="p-md-2 ansdiv">
                                                    {result.items[47]?.length > 0
                                                        ? (<CheckupAnswer items={result.items[47]} />


                                                        ) : ''
                                                    }
                                                    {result.items[53]?.length > 0
                                                        ? (<CheckupAnswer items={result.items[53]} />


                                                        ) : ''
                                                    }
                                                    {result.items[50]?.length > 0
                                                        ? (<CheckupAnswer items={result.items[50]} />


                                                        ) : ''
                                                    }
                                                    {result.items[54]?.length > 0
                                                        ? (<CheckupAnswer items={result.items[54]} />


                                                        ) : ''
                                                    }
                                                    {result.items[55]?.length > 0
                                                        ? (<CheckupAnswer items={result.items[55]} />


                                                        ) : ''
                                                    }
                                                    {result.items[57]?.length > 0
                                                        ? (<CheckupAnswer items={result.items[57]} />


                                                        ) : ''
                                                    }
                                                </div>
                                            </MDBCollapse>

                                        </div>
                                    </div>
                                </div>}

                                <section className="pb-2 mx-2 mt-0 row row-cols-md3 text-start g-3 justify-content-center">
                                    <div className="col-md-12">

                                        <Interpretremark />

                                    </div>
                                    <div className="col-md-12 mt-0">
                                        <span className="badge badge-light text-wrap text-start">{t("Remark desc")}</span>
                                    </div>
                                </section>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </>
    );
}