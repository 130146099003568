import {
    MDBListGroup, MDBListGroupItem, MDBBadge,
    MDBInput
    , MDBInputGroup
    , MDBBtn
    , MDBIcon
    , MDBTextArea
    , MDBTimepicker
}
    from 'mdb-react-ui-kit';
import React, { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import { useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFnsBuddhist from '../../helper/date-fns-be';
import { th, enUS } from 'date-fns/locale';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const EntryHNForm = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation('translations');
    const dateAdapter = useSelector(state => state.lang);
    const [selectedHN, setSelectedHN] = useState(props.hn);
    const [value, setValue] = useState(new Date());
    const [timevalue, setTimeValue] = useState(("0" + value.getHours()).slice(-2) + ":" + ("0" + value.getMinutes()).slice(-2))
    const componentList = ['add_blood_sugar', 'add_sbp', 'add_dbp', 'add_pulse', 'add_comment']

    const _getResult = (exclude) => {
        const listItem = [];
        for (var a of componentList.filter(e => e != exclude)) {
            listItem.push({
                hn: selectedHN,
                name: a.replace("add_", ""),
                value: document.getElementById(a).value,
                record_datetime: new Date(value.setHours(timevalue.split(":")[0], timevalue.split(":")[1], 0, 0))
            })
        }
        return listItem;
    }

    useImperativeHandle(ref, () => ({
        getResult: () => {

            return _getResult();
        },
        checkvalidField: () => {
            if (timevalue && value && timevalue.indexOf(":") > 0 && _getResult("add_comment").filter(e => e.value != "").length > 0)
                return true;
            else
                return false;
        }
    }))


    useEffect(() => {


    }, []);

    const handleKeyDown = function (e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;


    }





    return (<>

        <div className="px-md-4  pt-2 pt-md-4 row row-cols-1 row-cols-sm-2 row-cols-md-1  row-cols-xl-2 g-3 justify-content-center" action="" autoComplete="off">
            <div className="col">
                <div className="row justify-content-center">
                    <div className="col col-lg-10">
                        <div className="ms-mb-3 mb-3">
                            <div className="form-outline mb-3">
                                <MDBInputGroup >
                                    {dateAdapter !== "th" && <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS} >
                                        <DesktopDatePicker

                                            minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}
                                            maxDate={new Date()}
                                            onChange={(newValue) => setValue(newValue)}
                                            value={value}
                                            slotProps={{ textField: { sx: { width: "100%" }, className: "w-100", variant: 'outlined', size: 'small', id: "record_date", name: "record_date" } }}
                                            label={t("recordeddate")}
                                            format="dd/MMM/yyyy"
                                        />

                                    </LocalizationProvider>}
                                    {dateAdapter == "th" && <LocalizationProvider dateAdapter={AdapterDateFnsBuddhist} adapterLocale={th} >
                                        <DesktopDatePicker

                                            minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}
                                            maxDate={new Date()}
                                            onChange={(newValue) => setValue(newValue)}
                                            value={value}
                                            slotProps={{ textField: { sx: { width: "100%" }, className: "w-100", variant: 'outlined', size: 'small', id: "record_date", name: "record_date" } }}
                                            label={t("recordeddate")}

                                            format="dd/MMM/yyyy"
                                        />

                                    </LocalizationProvider>}

                                    <MDBTimepicker className='w-50' inline format="24h" onChange={(e) => { setTimeValue(e) }} defaultValue={timevalue} inputLabel={t("recordedtime")} submitLabel={t("Submit")} cancelLabel={t("Cancel")} clearLabel={t("Clear")} />
                                </MDBInputGroup>
                            </div>
                        </div >
                        <div className="ms-mb-3 mb-3">
                            <div className="form-outline mb-3">
                                <MDBInput label={t('Enter Blood suger')} id='add_blood_sugar' type='number' />
                            </div>
                        </div>
                        <div className="ms-mb-3 mb-3">
                            <div className="form-outline mb-3">
                                <MDBInput label={t('Enter SBP')} id='add_sbp' type='number' />
                            </div>
                        </div>
                        <div className="ms-mb-3 mb-3">
                            <div className="form-outline mb-3">
                                <MDBInput label={t('Enter DBP')} id='add_dbp' type='number' />
                            </div>
                        </div>
                        <div className="ms-mb-3 mb-3">
                            <div className="form-outline mb-3">
                                <MDBInput label={t('Enter Pulse')} id='add_pulse' type='number' />
                            </div>
                        </div>
                        <div className="ms-mb-3 mb-3">
                            <div className="form-outline mb-3">
                                <MDBTextArea
                                    onKeyDown={handleKeyDown}
                                    maxLength="500"
                                    id="add_comment"
                                    name="comment"
                                    label={t("comment")}
                                />
                            </div>
                        </div >
                    </div >
                </div >
            </div >


        </div >
    </>)
});

export default EntryHNForm;